import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import CircularProgress from '@mui/material/CircularProgress'

import { ContentHeader } from 'shared/components/contentHeader/contentHeader.component'
import Input from 'shared/components/input/input.component'
import { ThreeSixtyNewForm } from 'ts/interfaces/app.interfaces'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { validationNewRoleSchema } from './role.validation'
import { useCreateGuest, useGetGuestById, updateGuest } from 'api/questionnaire/questionnaire.api'
import { Guest } from 'api/questionnaire/questionnaire.interface'

const NewGuest = () => {
  const [t] = useTranslation(['threeSixty', 'global'])

  const navigate = useNavigate()
  const { guestId } = useParams()
  const { mutateAsync, status } = useCreateGuest()

  let dataRole = null
  let loading = false

  if (guestId) {
    const { isLoading, data } = useGetGuestById(guestId)

    console.log('data', data)

    dataRole = data?.data
    loading = isLoading
  }

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Guest>({
    resolver: yupResolver(validationNewRoleSchema),
  })
  const roleName = watch('name', 'Nombre de tu invitado' as string)

  const onSubmit: SubmitHandler<Guest> = async (guestData) => {
    try {
      if (guestId) {
        updateGuest(guestId, guestData).then((resp) => {
          if (resp.status === 200) {
            pushNotification(t(`messages.${guestId ? 'modified' : 'created'}`), 'success')
            reset({
              name: '',
              tickets: 0,
            })
            navigate('/dashboard')
          } else {
            pushNotification(t('letters:messages.error'), 'error')
          }
        })

        return
      }

      await mutateAsync(guestData)

      if (status === 'success' || status === 'idle') {
        pushNotification(t(`messages.${guestId ? 'modified' : 'created'}`), 'success')
        reset({
          name: '',
          tickets: 0,
        })
        navigate('/dashboard')
      } else {
        pushNotification(t('letters:messages.error'), 'error')
      }
    } catch (error) {
      pushNotification((error?.response?.data?.error?.code as string) ?? t('letters:messages.error'), 'error')
    }
  }

  useEffect(() => {
    if (dataRole) {
      reset({
        _id: dataRole?._id,
        name: dataRole?.name,
        tickets: dataRole?.tickets,
      })
    }
  }, [dataRole, reset])

  if (loading) return <CircularProgress />

  return (
    <>
      <ContentHeader
        title={t(`role.${guestId ? 'edition' : 'creation'}-header`)}
        breadCrumb={{ home: t('global:sidebar.home'), page: t('global:sidebar.roles') }}
      />

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='callout callout-primary'>
            <h5>{t(`role.${guestId ? 'edition' : 'creation'}-description-title`)}</h5>
            <p>{t(`role.${guestId ? 'edition' : 'creation'}-description-body`)}</p>
          </div>
        </div>
      </section>

      <section className='content rounded-0'>
        <div className='container-fluid'>
          <div className='card card-primary card-outline'>
            <div className='card-header border-0'>
              <div className='d-flex justify-content-between'>
                <h3 className='card-title'>
                  <strong>{roleName}</strong>
                </h3>
                <Link to='/dashboard'>{t('global:cruds.back-button')}</Link>
              </div>
            </div>
            <div className='card-body'>
              <form id='new-cluster-form' className='row' onSubmit={handleSubmit(onSubmit)}>
                <div className='col-md-12'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='name'>Nombre del Invitado</label>
                        <Input type='text' name='name' reg={register('name')} errors={errors} placeholder='Juan Perez...' />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <label htmlFor='name'>Boletos</label>
                        <Input type='number' name='tickets' reg={register('tickets')} errors={errors} placeholder='2' />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='offset-md-9 col-md-3'>
                      <div className='form-group'>
                        <button type='submit' className='btn btn-success btn-block'>
                          {t('global:cruds.save-button')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewGuest
