/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { Link, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { LoginForm } from 'ts/interfaces/app.interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import CircularProgress from '@mui/material/CircularProgress'

import LoginWrapper from 'shared/components/loginWrapper/loginWrapper.component'
import Input from 'shared/components/input/input.component'
// import Linkedin from 'shared/components/linkedin/linkedin.component'
// import { getTokenByPassword } from 'api/auth/auth.api'
import { useTranslation } from 'react-i18next'
import { pushNotification } from 'shared/helpers/notifications/notifications'
import { validationLoginSchema } from './login.validation'

const Login = () => {
  const [t, i18n] = useTranslation('login')

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(validationLoginSchema),
  })

  useEffect(() => {
    i18n.changeLanguage('es')
  }, [])

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    setLoading(true)

    try {
      // const {
      //   data: { token, username, tenantId },
      // } = await getTokenByPassword(data)

      console.log('data', data)
      if (data.username === 'Priscila' && data.password === 'Priscila777') {
        Cookies.set('token', 'loginPrisci')
        pushNotification(`${t('messages.welcome')}, ${data.username}!`, 'success')
      } else {
        pushNotification(t('messages.incorrect-data'), 'error')
      }
    } catch (error) {
      pushNotification(t('messages.incorrect-data'), 'error')
    } finally {
      setLoading(false)
      console.log('navi')
      navigate('/')
    }
  }

  return (
    <div className='hold-transition login-page'>
      {loading && <CircularProgress />}

      <LoginWrapper title={t('login.header')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='input-group mb-3'>
            <Input type='text' name='username' reg={register('username')} errors={errors} icon='fa fa-user' placeholder={t('login.username')} />
          </div>
          <div className='input-group mb-3'>
            <Input type='password' name='password' reg={register('password')} errors={errors} icon='fa fa-lock' placeholder={t('login.password')} />
          </div>
          <div className='row'>
            <div className='col-8'>
              <div className='icheck-primary'>
                <input type='checkbox' id='remember' />
                &nbsp;
                <label htmlFor='remember' className='form-check-label'>
                  {t('login.remember-me')}
                </label>
              </div>
            </div>
            {/* <!-- /.col --> */}
            <div className='col-4'>
              <button type='submit' className='btn btn-primary btn-block'>
                {t('login.sign-in-button')}
              </button>
            </div>
            {/* <!-- /.col --> */}
          </div>
        </form>

        {/* <div className='social-auth-links text-center mt-2 mb-3'>
          <Linkedin />
        </div> */}
        {/* <!-- /.social-auth-links --> */}

        {/* <p className='mb-1'>
          <Link to='/forgotPassword'>{t('login.forgot-password')}</Link>
        </p>
        <p className='mb-0'>
          <Link to='/register' className='text-center'>
            {t('login.register')}
          </Link>
        </p> */}
      </LoginWrapper>
    </div>
  )
}

export default Login
