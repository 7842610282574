import { Navigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isExpired, decodeToken } from 'react-jwt'
import { RootState } from 'redux/interfaces'
import { RecoveryPassword } from 'pages'
import { useCookies } from 'react-cookie'
import { DecodeToken } from 'ts/interfaces/app.interfaces'
import { Layout } from 'shared/components/layout/layout.component'

const useAuth = () => {
  const login = useSelector((state: RootState) => state.login?.data)
  const [cookies, setCookie] = useCookies(['token'])

  // let myDecodedToken: DecodeToken | any = decodeToken(cookies.token ?? '')
  // let isMyTokenExpired = isExpired(cookies.token ?? '')

  // if (isMyTokenExpired) {
  //   myDecodedToken = decodeToken(login?.token ?? '')
  //   isMyTokenExpired = isExpired(login?.token ?? '')
  //   setCookie('token', login?.token, { expires: new Date(myDecodedToken?.exp * 1000) })
  // }

  // return myDecodedToken && !isMyTokenExpired ? true : false

  return cookies.token === 'loginPrisci'
}

const verifyTokenParams = (userId: string, token: string) => {
  return token
}

const ProtectedRoute = () => {
  const { userId, token } = useParams()
  const isAuth = useAuth()

  if (userId && token && verifyTokenParams(userId, token)) {
    return <RecoveryPassword userId={userId} token={token} />
  }

  return isAuth ? <Layout /> : <Navigate to='/login' replace />
}

export default ProtectedRoute
